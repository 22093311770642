/* ----------------------------------------------------------------------------------------------------- */


/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';

$font-prefix: '~@ibm/plex';
// div.myimg {
//     content: url("~assets/images/logo/logo-.png");
//     width: 150px;
// }

// div.BoletoEBSA {
//     content: url("~assets/images/img/BoletoEBSA.png");
//     height: 22rem;
//     width: 100%;
// }

// div.BoletoCASCO {
//     content: url("~assets/images/img/BoletoCASCO.png");
//     height: 22rem;
//     width: 100%;
// }

// div.boletonuevo {
//     content: url("~assets/images/img/boletonuevo.jpg");
//     height: 22rem;
//     width: 100%;
// }

​ .width {
    width: 150px;
}

h1 {
    font-size: 32px;
    font-weight: bold;
    color: #707070;
}

.mat-tab-group .mat-tab-body-content {
    padding: 8px !important;
}

.mat-paginator .mat-paginator-container {
    padding: 0px 16px !important;
}

.mat-dialog-container {
    padding-bottom: 34px !important;
}

.swal2-styled.swal2-confirm {
    background-color: #FF4713 !important;
}

body.light .mat-flat-button {
    background-color: #FF4713 !important;
}

.mat-raised-button {
    background-color: #FF4713 !important;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    width: 70px;
    display: flex;
    justify-content: space-between;
}

.spinner div {
    width: 18px;
    height: 18px;
    background-color: #FF4713;
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out;
}

.bounce1 {
    animation-delay: -0.32s !important; 
}

.bounce2 {
    animation-delay: -0.16s !important; 
}

.bounce3 {
    animation-delay: 0s !important;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0); 
    }
    40% {
        transform: scale(1); 
    }
}


@media screen and (min-width: 1366px) {
    .limite {
        height: 250px;
        overflow: auto;
    }

    .mat-header-cell {
        font-size: 14px;
    }

    .mat-cell {
        font-size: 14px;
    }

    .displaynone2 {
        display: none !important;
    }
}

.label {
    font-style: italic;
    font-size: small;
    margin-bottom: 8px;
}

.over {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .displaynone {
        display: none !important;
    }

    .w100 {
        width: 100%;
    }

    .displayColumn {
        flex-direction: column !important;
    }

    .w100porcent {
        width: 100% !important;
    }
}

@media screen and (max-width: 768px) {
    .displaynone {
        display: none !important;
    }

    .displayColumn {
        flex-direction: column !important;
    }

    .w100porcent {
        width: 100% !important;
    }
}

.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
    margin-bottom: 6px !important;
}

table {
    width: 100%;
}

.mat-header-cell {
    font-weight: bold !important;
    font-size: 14px !important;
}


.timepicker {
    .timepicker__header {
        background-color: #FF4713 !important;
    }

    .clock-face__clock-hand {
        background-color: #FF4713 !important;

        &::after {
            background-color: #FF4713 !important;
        }

        &::before {
            border-color: #FF4713 !important;
        }
    }

    .clock-face__number {
        >span.active {
            background-color: #FF4713 !important;
        }
    }

    button:not(.timepicker-dial__item) {
        color: #FF4713 !important;
    }
}

.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
    border-radius: 4px !important;
}

.limite {
    height: 200px;
    overflow: auto;
}

.mat-icon-button {
    min-height: 39px !important;
}


.right {
    text-align: right;
}

.loadings {
    width: 100%;
    height: 100%;
}

.modalOn {
    position: absolute;
    z-index: 99999;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    cursor: wait;
    background-color: rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
    z-index: 2001;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin-left: 1%;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FF4713;
    margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}

.swal2-title {
    font-size: 2rem !important;
}

.swal2-popup {
    width: 27rem !important;
}

.popup-class {
    font-size: 1rem !important;
}

.mat-dialog-container {
    width: 100% !important;
}


fuse-vertical-navigation {
    width: 100% !important;
    max-height: 100% !important;
}

@media only screen and (max-width: 599px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 90% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 90% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 26% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1419px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 21% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width: 1420px) and (max-width: 1719px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 17% !important;
        z-index: 1000 !important;
    }
}


@media only screen and (min-width: 1720px) and (max-width: 1919px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 17% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 5000px) {
    fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        position: fixed !important;
        width: 15% !important;
        z-index: 1000 !important;
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.textoDialog {
    text-align: center;
}
